import '../styles/index.scss';
import React from 'react';

// import Header from '../components/header';
import Header from '../components/HeaderConfirm';
import Footer from '../components/Footer';
import SEO from '../components/seo';

const Cookies = () => {
	return (
		<React.Fragment>
			<SEO title="Cookies" description="Cookies" />
			<Header />
			<div className="cookies-container">
				<div className="cookies-section-container">
					<div className="cookies-title">Cookies</div>

					<div className="cookies-section-description">
						Cookies are tiny files that are downloaded to your computer, to improve your experience. This
						page describes what information they gather, how we use it and why we sometimes need to store
						these cookies. We will also share how you can prevent these cookies from being stored however
						this may downgrade or 'break' certain elements of the sites functionality.
					</div>
				</div>
				<div className="cookies-section-container">
					<div className="cookies-section-title">How We Use Cookies</div>

					<div className="cookies-section-description">
						We use cookies for a variety of reasons detailed below. Unfortunately in most cases there are no
						industry standard options for disabling cookies without completely disabling the functionality
						and features they add to this site. It is recommended that you leave on all cookies if you are
						not sure whether you need them or not in case they are used to provide a service that you use.
					</div>

					{/* <div className="cookies-section-description"></div>
        <div className="cookies-section-description"></div>
        <div className="cookies-section-description"></div>
        <div className="cookies-section-description"></div>
        <div className="cookies-section-description"></div>
        <div className="cookies-section-description"></div> */}
				</div>

				<div className="cookies-section-container">
					<div className="cookies-section-title">Disabling Cookies</div>
					<div className="cookies-section-description">
						You can prevent the setting of cookies by adjusting the settings on your browser (see your
						browser Help for how to do this). Be aware that disabling cookies will affect the functionality
						of this and many other websites that you visit. Disabling cookies will usually result in also
						disabling certain functionality and features of the this site. Therefore it is recommended that
						you do not disable cookies.
					</div>
				</div>

				<div className="cookies-section-container">
					<div className="cookies-section-title">Email newsletters related cookies</div>
					<div className="cookies-section-description">
						This site offers newsletter or email subscription services and cookies may be used to remember
						if you are already registered and whether to show certain notifications which might only be
						valid to subscribed/unsubscribed users.
					</div>
				</div>
				<div className="cookies-section-container">
					<div className="cookies-section-title">Order processing related cookies</div>
					<div className="cookies-section-description">
						This site offers e-commerce or payment facilities and some cookies are essential to ensure that
						your order is remembered between pages so that we can process it properly.
					</div>
				</div>
				<div className="cookies-section-container">
					<div className="cookies-section-title">Third Party cookies</div>
					<div className="cookies-section-description">
						<div>
							In some special cases we also use cookies provided by trusted third parties. The following
							section details which third party cookies you might encounter through this site. This site
							uses Google Analytics which is one of the most widespread and trusted analytics solution on
							the web for helping us to understand how you use the site and ways that we can improve your
							experience. These cookies may track things such as how long you spend on the site and the
							pages that you visit so we can continue to produce engaging content. For more information on
							Google Analytics cookies, see the official Google Analytics page.
						</div>
						<br />
						<div>
							From time to time we test new features and make subtle changes to the way that the site is
							delivered. When we are still testing new features these cookies may be used to ensure that
							you receive a consistent experience whilst on the site whilst ensuring we understand which
							optimisations our users appreciate the most. As we sell products it's important for us to
							understand statistics about how many of the visitors to our site actually make a purchase
							and as such this is the kind of data that these cookies will track. This is important to you
							as it means that we can accurately make business predictions that allow us to monitor our
							advertising and product costs to ensure the best possible price.
						</div>
						<br />
						<div>
							Several partners advertise on our behalf and affiliate tracking cookies simply allow us to
							see if our customers have come to the site through one of our partner sites so that we can
							credit them appropriately and where applicable allow our affiliate partners to provide any
							bonus that they may provide you for making a purchase.
						</div>
						<br />
						<div>
							We also use social media buttons and/or plugins on this site that allow you to connect with
							your social network in various ways. For these to work the following social media sites
							including Facebook, Instagram Twitter, Pinterest, will set cookies through our site which
							may be used to enhance your profile on their site or contribute to the data they hold for
							various purposes outlined in their respective privacy policies.
						</div>
					</div>
				</div>
				<div className="cookies-section-container">
					<div className="cookies-section-title">More Information</div>
					<div className="cookies-section-description">
						Hopefully that has clarified things for you and as was previously mentioned if there is
						something that you aren't sure whether you need or not it's usually safer to leave cookies
						enabled in case it does interact with one of the features you use on our site.
					</div>
				</div>
			</div>
			<Footer />
		</React.Fragment>
	);
};

export default Cookies;
